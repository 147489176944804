import {
  notification,
  forum
} from "../../js/path";

export default {
  data() {
    return {
      template_ref_no: "",
      table_header: [],
      form: {
        name: "",
        content: "",
        subject: "",
        type: "sms",
        brand_forum_id: "",
        variables: "",
        template_ref_no: ""
      },
      fields: [{
          key: "template_ref_no",
          label: "Template Reference No.",
        },
        {
          key: "name",
        },
        {
          key: "content",
          label: "Email HTML Content"
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      title: "SMS Notification",
      items: [{
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filter: null,
      filterOn: [],
      params: "",
      currentPage: 1,
      activeTab: "all",
      key: 0,
      getForum: [],
    };
  },
  methods: {
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData(this.activeTab);
        }
      } else if (this.filter.length == 0) this.fetchData(this.activeTab);
    },
    searchFor() {
      if (this.filter.length > 0) {
        this.fetchData(this.activeTab);
      } else {
        this.fetchData(this.activeTab);
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchForum(query) {
      const url = forum.fetchAllActiveForum;
      const data = await this.getRequest(url + "&search=" + query);
      if (data.status) {
        this.getForum = data.response.data;
      }
    },
    async fetchSingleForum(id) {
      const url = forum.forumUrl;
      const data = await this.getRequest(url + "/" + id);
      if (data.status) {
        this.form.brand_forum_id = data.response;
      }
    },
    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = null;
        if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              notification.fetchSMS + "&filter=" + txt + "&search=" + this.filter;
          } else {
            url = notification.fetchSMS + "&filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              notification.fetchSMS + "&filter=" + txt + "&search=" + this.filter;
          } else {
            url = notification.fetchSMS + "&filter=" + txt;
          }
        }
        url += this.params;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          this.tableData = responseData;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async updateStatus(template_ref_no) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex(
          (e) => e.template_ref_no == template_ref_no
        );
        const url = notification.smsStatusUpdate;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
          template_ref_no: template_ref_no,
        });
        if (data.status) {
          this.tableData.data[index].is_active = !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSMS(template_ref_no) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = notification.smsUrl + "/" + template_ref_no;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (responseData.template_ref_no != null && responseData.template_ref_no != "") {
            this.template_ref_no = responseData.template_ref_no;
          }
          if (responseData.name != null && responseData.name != "") {
            this.form.name = responseData.name;
          }
          if (responseData.subject != null && responseData.subject != "") {
            this.form.subject = responseData.subject;
          }
          if (responseData.content != null && responseData.content != "") {
            this.form.content = responseData.content;
          }
          if (responseData.variables != null && responseData.variables != "") {
            this.form.variables = responseData.variables == 0 ? 0 : responseData.variables;
          }
          if (responseData.brand_forum_id != "" && responseData.brand_forum_id != null) {
            this.form.brand_forum_id = this.fetchSingleForum(responseData.brand_forum_id);
          }
          this.form.type = "sms";
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields"
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        let url = notification.smsUrl;
        if (this.$route.name == 'edit-sms-notification') {
          url = notification.smsUrl + '/' + this.template_ref_no;
        }
        let dataAppend = new FormData();
        for (var key in this.form) {
          if (key != "brand_forum_id") {
            dataAppend.append(key, this.form[key]);
          }
        }
        if (
          this.form.brand_forum_id != "" &&
          this.form.brand_forum_id != null
        ) {
          dataAppend.append(
            "brand_forum_id",
            this.form.brand_forum_id.id
          );
        }
        if (this.$route.name == 'edit-sms-notification') {
          dataAppend.append('_method', 'put');
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push("/sms-notification");
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteSMS(template_ref_no) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex(
          (e) => e.template_ref_no === template_ref_no
        );
        const url = notification.smsUrl + "/" + template_ref_no;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async restoreSMS(template_ref_no) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex(
          (e) => e.template_ref_no === template_ref_no
        );
        const url = notification.restoreSMS + "/" + template_ref_no;
        const data = await this.postRequest(url);
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Fetching error!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    tabActive() {
      if (this.activeTab == "trash") {
        if (this.table_header.includes("delete")) {
          let index = this.fields.findIndex(
            (item) => item.key == "delete"
          );
          this.fields[index] = {
            key: "restore",
          };
          let table_index = this.table_header.findIndex(
            (item) => item == "delete"
          );
          this.table_header[table_index] = "restore";
        } else {
          this.table_header.push("restore");
          this.fields.push({
            key: "restore",
          });
        }

        if (!this.can("restore-sms-notification")) {
          let index = this.fields.findIndex(
            (item) => item.key == "restore"
          );
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "restore"
            );
            this.table_header.splice(table_index, 1);
          }
        }

        let index = this.fields.findIndex((item) => item.key == "edit");
        if (index !== -1) {
          this.fields.splice(index, 1);
          let table_index = this.table_header.findIndex(
            (item) => item == "edit"
          );
          this.table_header.splice(table_index, 1);
        }
      } else {
        if (this.table_header.includes("restore")) {
          if (!this.can("edit-sms-notification")) {
            this.fields[3] = {
              key: "delete",
            };
          } else {
            this.fields[4] = {
              key: "delete",
            };
          }

          let table_index = this.table_header.findIndex(
            (item) => item == "restore"
          );
          this.table_header[table_index] = "delete";
        }

        if (!this.can("edit-sms-notification")) {
          let index = this.fields.findIndex((item) => item.key == "edit");
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "edit"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("edit")) {
            this.table_header.push("edit");
            this.fields[3] = {
              key: "edit",
            };
          }
        }

        if (!this.can("delete-sms-notification")) {
          let index = this.fields.findIndex(
            (item) => item.key == "delete"
          );
          if (index !== -1) {
            this.fields.splice(index, 1);
            let table_index = this.table_header.findIndex(
              (item) => item == "delete"
            );
            this.table_header.splice(table_index, 1);
          }
        } else {
          if (!this.table_header.includes("delete")) {
            this.table_header.push("delete");
            this.fields[4] = {
              key: "delete",
            };
          }
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.params = `&page=${value}`;
        this.fetchData(this.activeTab);
      },
    },
    activeTab(v) {
      if (v) this.tabActive();
    },
  },
  created() {
    if (this.$route.name == "add-sms-notification" || this.$route.name == "edit-sms-notification") {
      if (this.$route.name == "edit-sms-notification") {
        this.fetchSMS(this.$route.params.template_ref_no);
      }
    } else {
      this.fetchData("all");
    }
    this.fields.map((item) => {
      this.table_header.push(item.key);
    });
    this.tabActive();
  },
};